import './App.css';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import { Card } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/esm/ListGroup';

function App() {
  return (
    <div className="App">
      <header>
        <Navbar bg="dark" variant="dark" expand="lg">
          <Container>
            <Navbar.Brand href="https://ovixia.com">OVIXIA</Navbar.Brand>
          </Container>
        </Navbar>
      </header>
      <main>
        <Container>
          <Row className="px-4 my-5">
            <Col sm={5}>
              <div class="fluidMedia">
                <iframe src="https://js.filkersplayers.com/ds/stream/tkNef97kaE-Z75sGHlU10Gja4jSl08AttgsHqBoDNkM" title="stream"> </iframe>
              </div>
            </Col>

            <Col sm={7}>
              <h1 className="font-weight-bold py-1" style={{ fontSize: 90 }}>OMNICHANNEL SOLUTION TO REACH THE CONSUMER DIRECTLY</h1>
              <p className='mt-4 text-left'>
                Display media signage is an easy way to reach your customers. Whether you have ten or one million customers,
                the software on your display screens allows you to engage your customers with the right message at the right time.
                Display media has been proven to: increase basket size, drive traffic, increase quality perception, and improve overall customer experience.
              </p>

              <ListGroup className="list-group-flush">
                <ListGroup.Item>Digital Signage is the most powerful media of all time</ListGroup.Item>
                <ListGroup.Item>Digital signage uses WOW to engage your customers on your terms</ListGroup.Item>
                <ListGroup.Item>Digital signage is not just for display, it is for engagement</ListGroup.Item>
                <ListGroup.Item>Digital signage for business uses video, audio, still images and text</ListGroup.Item>
                <ListGroup.Item>Now, mix with dynamic data. Let´s talk...</ListGroup.Item>
              </ListGroup>
              <br></br>
              <Button href="https://ovixia.com/contact" variant="outline-dark">Contact us</Button>
            </Col>
          </Row>
          <Row>
            <Card className="text-center bg-dark text-white my-5 py-4">
              <Card.Body>
                We are experts generating content with connected data in real time.
              </Card.Body>
            </Card>
          </Row>
          <Row>
            <Col className="px-1 my-3">
              <Card style={{ width: '24rem' }}>
                <Card.Img variant="top" src="https://cdn.filkersmail.com/ovixia/players/digital-01.jpg" />
                <Card.Body>
                  <Card.Title>Universal</Card.Title>
                  <Card.Text>
                    We connect with any platform generating all kinds of streams prepared to connect with any device.
                  </Card.Text>
                  <Button href="https://ovixia.com/contact" variant="outline-dark">Contact us</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col className="px-1 my-3">
              <Card style={{ width: '24rem' }}>
                <Card.Img variant="top" src="https://cdn.filkersmail.com/ovixia/players/digital-02.jpg" />
                <Card.Body>
                  <Card.Title>Streams</Card.Title>
                  <Card.Text>
                    You can connect our playlists with your player regardless of the brand. SMIL, MRSS, HTML, MP4…
                  </Card.Text>
                  <Button href="https://ovixia.com/contact" variant="outline-dark">Contact us</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col className="px-1 my-3">
              <Card style={{ width: '24rem' }}>
                <Card.Img variant="top" src="https://cdn.filkersmail.com/ovixia/players/digital-03.jpg" />
                <Card.Body>
                  <Card.Title>Affordable solutions</Card.Title>
                  <Card.Text>
                    If you don't have a specific software or player, you can try our basic solution.
                  </Card.Text>
                  <Button href="https://ovixia.com/contact" variant="outline-dark">Contact us</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="px-4 my-5">
            <Col sm={6}>
              <div class="fluidMedia">
                <iframe src="https://js.filkersplayers.com/ds/stream/5pVdlHF7YcPQ0Bs4goFqwGevPN2JIeeihUpl64yCvs8" title="stream2"> </iframe>
              </div>
            </Col>
            <Col sm={6}>
              <div class="fluidMedia">
                <iframe src="https://js.filkersplayers.com/ds/stream/tHaRKKAoK3DiBShOhWDzbyRIiuDygApkhXovh0T9_WE" title="stream3"> </iframe>
              </div>
            </Col>
          </Row>

        </Container>
      </main>
      <footer className="py-5 my-5 bg-dark">
        <Container className="px-4">
          <p className="text-center text-white">OVIXIA - SMART MEDIA </p>
        </Container>
      </footer>
    </div>
  );
}

export default App;
